import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import auth from "../../auth";
import https from "https";
const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});
export class BuscarSitio extends Component {
  state = {
    sitios2: [],
    urltodash: "",
    tipo: "user"
  };

  async componentDidMount() {
    //pedir sitios al servidor
    const res = await instance.get(
      process.env.REACT_APP_URL_MACHOTE + "sitios/nuevo/dr019-2/"
    );
    this.setState({ sitios2: res.data });
    const tip = JSON.parse(
      localStorage.getItem("tipo" + process.env.REACT_APP_COOKIE)
    );
    if (tip.tipo === "admin") {
      this.setState({ tipo: tip.tipo });
    }
  }

  valorSelect(event) {
    var sp = event.target.value.split(",");
    this.setState({ urltodash: "dashboard/" + sp[1] + "/" + sp[0] }, () => {
      //console.log(this.state.urltodash);
    });
  }

  onClick() {
    window.open(this.state.urltodash, "_blank");
    //console.log(this.state.urltodash);
  }
  onClick2() {
    window.open("admin/modsitios", "_blank");
    //console.log(this.state.urltodash);
  }

  render() {
    const styles = this.state.tipo === "admin" ? {} : { display: "none" };
    return (
      <div className="row">
        <div className="input-group ">
          <div className="input-group-prepend">
            <label
              className="input-group-text text-info"
              htmlFor="inputGroupSelect01"
            >
              Sitios
            </label>
          </div>
          <select
            className="custom-select"
            id="inputGroupSelect01"
            onChange={this.valorSelect.bind(this)}
          >
            <option key="0" defaultValue>
              Elije un sitio...
            </option>

            {this.state.sitios2.map((sitio) => (
              <option
                key={sitio._id}
                multiple={true}
                value={[sitio.idSitio, sitio.tipo]}
              >
                {sitio.nombre}
              </option>
            ))}
          </select>
          <div className="input-group-append">
            <button
              className="btn btn-warning"
              type="button"
              onClick={this.onClick.bind(this)}
            >
              Ir Al Sitio
            </button>
            <button
              style={styles}
              className="btn btn-primary"
              type="button"
              onClick={this.onClick2.bind(this)}
            >
              Administración
            </button>
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => {
                auth.logout(() => {
                  this.props.history.push("/");
                });
              }}
            >
              Salir
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BuscarSitio);
